import React from 'react';

const NewPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="px-8 py-6 text-center bg-white dark:bg-gray-800 shadow-lg rounded-lg">
        <h1 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">Account Activation Required</h1>
        <p className="text-gray-600 dark:text-gray-300">
          Thank you for registering. To enable your account, please contact Cruise Saudi.
        </p>
        <p className="mt-4 text-gray-600 dark:text-gray-300">
          For assistance, you can reach out to our support team at:
        </p>
        <a 
          href="mailto:support@cruisesaudi.com" 
          className="mt-4 inline-block text-blue-600 dark:text-blue-400 hover:underline"
        >
          it@cruisesaudi.com
        </a>
      </div>
    </div>
  );
};

export default NewPage;
