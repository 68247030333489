import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const verifyToken = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                setLoading(false);
                navigate("/signin");
                return;
            }

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_CSH_BASE_URL}/api/verify-token`,
                    {},
                    {
                        headers: {
                            Authorization: token,
                        },
                    }
                );

                if (response.status === 200) {
                    setUser(response.data.user);
                    const storedUser = JSON.parse(localStorage.getItem("user"));
                    if (storedUser && storedUser.rules) {
                        const rules = storedUser.rules;
                        if (Array.isArray(rules) && rules.includes("New")) {
                            navigate("/new");
                        }
                    }
                } else {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    navigate("/signin");
                }
            } catch (error) {
                console.error("Error verifying token:", error);
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                navigate("/signin");
            } finally {
                setLoading(false);
            }
        };

        verifyToken();
    }, [navigate]);

    return (
        <AuthContext.Provider value={{ user, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);