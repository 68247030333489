import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

export default function ProtectedRoute({ children }) {
    const { user, loading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading && user === null) {
            navigate("/signin", { replace: true });
        }
    }, [navigate, user, loading]);

    if (loading) {
        return <div>Loading...</div>; // You can replace this with a loading spinner or any other component
    }

    return user ? children : null;
}